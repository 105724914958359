import './index.scss';
import PropTypes from 'prop-types';

const LoaderAnimate = ({type}) => {
  const isLightVersion = window.location.pathname === '/' || window.location.pathname === '/calculator' ? true : false;
  if(type === "MODULE"){
    return (
      <div className={isLightVersion ? 'loader light' : 'loader-module'}>
        <div className="loader-module__wrapper">
          <svg width="101" height="123" viewBox="0 0 141 163" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M101.658 11.1534H96.2158V85.0139H101.658V95.4238H105.121V85.0139H110.563V11.1534H105.121V0.247803H101.658V11.1534Z"
              fill="#0072FF"
            />
            <path
              d="M69.2527 45.1094H63.8105V118.97H69.2527V129.38H72.7158V118.97H78.1579V45.1094H72.7158V34.2039H69.2527V45.1094Z"
              fill="#0072FF"
            />
            <path
              d="M35.1158 78.2648H29.6737V151.741H35.1158V162.097H38.579V151.741H44.0211V78.2648H38.579V67.4163H35.1158V78.2648Z"
              fill="#0072FF"
            />
            {isLightVersion ? (
              <>
                <path d="M140.495 11.897H127.137H127.09V138.303H64.3053V151.687H140.495V11.897Z" fill="#031222" />
                <path d="M0.979004 151.687H14.3369H14.3834V25.2811H77.1685V11.897H0.979004V151.687Z" fill="#031222" />
              </>
            ) : (
              <>
                <path d="M140.495 11.897H127.137H127.09V138.303H64.3053V151.687H140.495V11.897Z" fill="#EAECEF" />
                <path d="M0.979004 151.687H14.3369H14.3834V25.2811H77.1685V11.897H0.979004V151.687Z" fill="#EAECEF" />
              </>
            )}
          </svg>
          <div className="loader-module_lds">
            <div className="loader-module_lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={isLightVersion ? 'loader light' : 'loader'}>
      <div className='loader__wrapper'>
        <svg width='101' height='123' viewBox='0 0 141 163' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M101.658 11.1534H96.2158V85.0139H101.658V95.4238H105.121V85.0139H110.563V11.1534H105.121V0.247803H101.658V11.1534Z'
            fill='#0072FF'
          />
          <path
            d='M69.2527 45.1094H63.8105V118.97H69.2527V129.38H72.7158V118.97H78.1579V45.1094H72.7158V34.2039H69.2527V45.1094Z'
            fill='#0072FF'
          />
          <path
            d='M35.1158 78.2648H29.6737V151.741H35.1158V162.097H38.579V151.741H44.0211V78.2648H38.579V67.4163H35.1158V78.2648Z'
            fill='#0072FF'
          />
          {isLightVersion ? (
            <>
              <path d='M140.495 11.897H127.137H127.09V138.303H64.3053V151.687H140.495V11.897Z' fill='#031222' />
              <path d='M0.979004 151.687H14.3369H14.3834V25.2811H77.1685V11.897H0.979004V151.687Z' fill='#031222' />
            </>
          ) : (
            <>
              <path d='M140.495 11.897H127.137H127.09V138.303H64.3053V151.687H140.495V11.897Z' fill='#EAECEF' />
              <path d='M0.979004 151.687H14.3369H14.3834V25.2811H77.1685V11.897H0.979004V151.687Z' fill='#EAECEF' />
            </>
          )}
        </svg>
        <div className='lds'>
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
LoaderAnimate.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
  ]),
}
export default LoaderAnimate;
