export const API_URL = 'https://api.generaltradergroup.org/api/v1/site';
export const WS_API_URL = 'wss://api.generaltradergroup.org/api/v1/site';

export const authPopupTypes = {
  registration: 'registration',
  confirmRegistration: 'confirmRegistration',
  login: 'login',
  reset: 'reset',
  confirmReset: 'confirmReset',
  successReset: 'successReset',

  confirmLogin: 'confirmLogin',
};

export const twoFactorAuthTypes = {
  choseType2FA: 'chose-type-2fa',
  confirmCode2FA: 'confirm-code-2fa',
};

export const accountStatisticsHistoryTypeTypes = {
  personalSales: 'personal_money',
  teamSales: 'team_money',
  personalRegistry: 'personal_registry',
  teamRegistry: 'team_registry',
};

export const profileMainInfoTypes = {
  firstName: 'first_name',
  lastName: 'last_name',
  middleName: 'middle_name',
  birtdayTimestamp: 'birthday_timestamp',
  country: 'country',
  phone: 'phone',
  telegram: 'telegram',
};

export const changePasswordTypes = {
  verificationUserId: 'verification_user_id',
  verificationKey: 'verification_key',
  verification_type: 'verification_type',
  oldPassword: 'old_password',
  newPassword: 'new_password',
};

export const getPassportVerificationStatusTypes = {
  status: {
    noVerification: 0,
    inProccess: 1,
    verificated: 2,
    verificationError: 3,
  },
};

export const calculatorTypes = {
  compoundComponent: 'compoundComponent', // сложный процент
  closeMortgage: 'closeMortgage', // закрытие ипотеки
  vacation: 'vacation', // накопить на отпуск
  yearlyBuy: 'yearlyBuy', // ежегодная покупка
  buyCar: 'buyCar', // покупка машины
  calcPartner: 'calcPartner', // калькулятор партнера
  payEducation: 'payEducation', // оплата за обучение
  financeFreedom: 'financeFreedom', // финансовая свобода
};

export const calculatorIds = {
  compoundComponent: 'hard', // сложный процент
  closeMortgage: 'close', // закрытие ипотеки
  vacation: 'save', // накопить на отпуск
  yearlyBuy: 'buy', // ежегодная покупка
  buyCar: 'buy-2', // покупка машины
  calcPartner: 'calc', // калькулятор партнера
  payEducation: 'study', // оплата за обучение
  financeFreedom: 'free', // финансовая свобода
};
