import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const LkLayout = lazy(() => import('./LkLayout'));

const LandingLayout = lazy(() => import('./LandingLayout'));

const LandingPage = lazy(() => import('./LandingPage'));

const NewsPage = lazy(() => import('./NewsPage'));

const EventsPage = lazy(() => import('./EventsPage'));

const SupportPage = lazy(() => import('./SupportPage'));

const EventsSinglePage = lazy(() => import('./SingleEventsPage'));

const NewsSinglePage = lazy(() => import('./SingleNewsPage'));

const AccountPage = lazy(() => import('./AccountPage'));

const CartPage = lazy(() => import('./CartPage'));

const P2pLayout = lazy(() => import('./P2pLayout'));
const P2pOffersPage = lazy(() => import('./P2pOffersPage'));
const P2pOrdersPage = lazy(() => import('./P2pOrdersPage'));
const P2pOrderPage = lazy(() => import('./P2pOrderPage'));

const P2pProfilePage = lazy(() => import('./P2pProfilePage'));
const P2pProfileOffers = lazy(() => import('widgets/P2pProfileOffers'));
const P2pProfileFeedbacks = lazy(() => import('widgets/P2pProfileFeedbacks'));

const ProfilePage = lazy(() => import('./ProfilePage'));

const ProductsPage = lazy(() => import('./ProductsPage'));

const FinancePage = lazy(() => import('./FinancePage'));

const PartnersPage = lazy(() => import('./PartnersPage'));

const MarketingPage = lazy(() => import('./MarketingPage'));
const CalculatorPage = lazy(() => import('./CalculatorPage'));

const SingleSupportPage = lazy(() => import('./SingleSupportPage'));

const SignInPage = lazy(() => import('./SignInPage'));
const RegistrationPage = lazy(() => import('./RegistrationPage'));

export const Routing = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate to="auth/login" />} />
      </Route>
      <Route path="calculator">
        <Route index element={<Navigate to="/auth/login" />} />
      </Route>

      {/* <Route path="/" element={<LandingLayout />}>
        <Route index element={<Navigate to="registration" />} />

        <Route index element={<LandingPage />} />
        <Route path="calculator" element={<CalculatorPage />} />
      </Route> */}

      <Route path="/auth">
        <Route index element={<Navigate to="registration" />} />
        <Route path="login" element={<SignInPage />} />
        <Route path="registration" element={<RegistrationPage />} />
      </Route>

      <Route path="/lk" element={<LkLayout />}>
        <Route index element={<Navigate to="account" />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="finance" element={<FinancePage />} />

        <Route path="cart" element={<CartPage />} />

        <Route path="p2p" element={<P2pLayout />}>
          <Route index element={<Navigate to="offers" replace={true} />} />

          <Route path="offers" element={<P2pOffersPage />} />

          <Route path="orders" exact element={<P2pOrdersPage />} />
          <Route path="orders/:orderId" exact element={<P2pOrderPage />} />

          <Route path="profile/:userName" element={<P2pProfilePage />}>
            <Route index element={<Navigate to="offers" replace={true} />} />
            <Route path="offers" element={<P2pProfileOffers />} />
            <Route path="feedbacks" element={<P2pProfileFeedbacks />} />
          </Route>
        </Route>

        <Route path="partners" element={<PartnersPage />} />
        <Route path="partners/marketing" element={<MarketingPage />} />

        <Route path="calculator" element={<CalculatorPage />} />

        <Route path="profile" element={<ProfilePage />} />

        <Route path="events" element={<EventsPage />} />
        <Route path="events/:id" element={<EventsSinglePage />} />

        <Route path="news" element={<NewsPage />} />
        <Route path="news/:id" element={<NewsSinglePage />} />

        <Route path="support" element={<SupportPage />} />
        <Route path="support/:id" element={<SingleSupportPage />} />

        <Route
          path="*"
          element={
            <div className="col-md-10">
              <section className="main__content">
                <div className="not-found" style={{ fontSize: '24px', color: 'white' }}>
                  NOT FOUND 404
                </div>
              </section>
            </div>
          }
        />
      </Route>
    </Routes>
  );
};
